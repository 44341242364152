import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';


export interface CellidToENBConverter {
  net: 'LTE'| 'UMTS' | 'GSM';
  cellid: number;
}

export interface CellidToENBConverterResult {
  net: 'LTE'| 'UMTS' | 'GSM';
  cellid: number;
  sectorid?: number;
  siteid: number;
  cellidhex: string;
  sectoridhex?: string;
  siteidhex: string;
  rncid?: number;
}

@Component({
  selector: 'app-cell-id-to-enb-converter-page',
  templateUrl: './cell-id-to-enb-converter-page.component.html',
  styleUrls: ['./cell-id-to-enb-converter-page.component.css']
})
export class CellIdToEnbConverterPageComponent implements OnInit {

  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Cell ID to eNB Converter | ' + environment.title);
  }

  submitDisabled: boolean;
  formData: CellidToENBConverter = { net: 'LTE', cellid: null};
  cellid: string;

  cellidToENBConverterResult: CellidToENBConverterResult;

  submitForm() {
    this.submitDisabled = true;

    let siteid = this.formData.cellid / 10 ^ 0;
    let sectorid = null;
    let sectoridhex = null;
    let rncid = null;
    
    if(this.formData.net == 'LTE') {
      sectorid = this.formData.cellid & 0xff;
      siteid = this.formData.cellid >> 8
      sectoridhex = sectorid.toString(16);
    }

    if(this.formData.net == 'UMTS') {
      rncid = this.formData.cellid >> 16;
    }

    let cellidhex = this.formData.cellid.toString(16);
    let siteidhex = siteid.toString(16);

    this.cellidToENBConverterResult = {
      net: this.formData.net,
      cellid: this.formData.cellid,
      cellidhex: cellidhex,
      sectorid: sectorid,
      siteid: siteid,
      sectoridhex: sectoridhex,
      siteidhex: siteidhex,
      rncid: rncid
    }
    
    this.submitDisabled = false;
  }
}
