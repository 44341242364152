<div class="container">
    <h1> Cell ID to eNB Converter</h1>
    <p>Use this page calculate things such as eNB ID/RNC-ID/BSIC or hex values of IDs. Simply select network type and type in the LCID/CID.</p>
    <form method="POST" #cellidForm="ngForm" (submit)="submitForm()">
        <div class="form-group">
            <label for="net">Select network type</label>
            <select name="net" id="net" class="form-control" [(ngModel)]="formData.net">
                <option value="LTE">4G LTE</option>
                <option value="UMTS">3G UMTS</option>
                <option value="GSM">2G GSM</option>
            </select>
        </div>
        <div class="form-group">
            <label for="cellid">Cell ID</label>
            <input type="number" class="form-control" [class.in-valid]="formData.cellid == null" id="cellid" name="cellid" max="16" [(ngModel)]="formData.cellid" required />
            <div class="invalid-feedback">Invalid value, please give Cell ID number</div>
        </div>
        <button [disabled]="submitDisabled" type="submit" name="submit"
        class="btn btn-primary" [disabled]="!cellidForm.form.valid || formData.cellid == null">Submit</button>
    </form>
    <br />
    <div class="table-responsive">
        <table class="table" *ngIf="cellidToENBConverterResult != null">
            <tr *ngIf="formData.net != null">
                <th> Network Type</th>
                <td>
                    {{cellidToENBConverterResult.net}}
                </td>
            </tr>
            <tr *ngIf="cellidToENBConverterResult.cellid != null">
                <th> Cell Identify</th>
                <td>
                    {{cellidToENBConverterResult.cellid}}
                </td>
            </tr>
            <tr *ngIf="cellidToENBConverterResult.cellidhex != null">
                <th>
                    Cell Identify (hex)
                </th>
                <td>
                    {{cellidToENBConverterResult.cellidhex}}
                </td>
            </tr>
            <tr *ngIf="cellidToENBConverterResult.siteid != null">
                <th>
                    <ng-container *ngIf="cellidToENBConverterResult.net == 'LTE'">eNB ID</ng-container>
                    <ng-container *ngIf="cellidToENBConverterResult.net == 'UMTS'">NB ID</ng-container>
                    <ng-container *ngIf="cellidToENBConverterResult.net == 'GSM'">BTS ID</ng-container>
                </th>
                <td>
                    {{cellidToENBConverterResult.siteid}}
                </td>
            </tr>
            <tr *ngIf="cellidToENBConverterResult.siteidhex != null">
                <th>
                    <ng-container *ngIf="cellidToENBConverterResult.net == 'LTE'">eNB ID (hex)</ng-container>
                    <ng-container *ngIf="cellidToENBConverterResult.net == 'UMTS'">NB ID (hex)</ng-container>
                    <ng-container *ngIf="cellidToENBConverterResult.net == 'GSM'">BTS ID (hex)</ng-container>
                </th>
                <td>
                    {{cellidToENBConverterResult.siteidhex}}
                </td>
            </tr>
            <tr *ngIf="cellidToENBConverterResult.sectorid != null && cellidToENBConverterResult.net == 'LTE'">
                <th>
                    Sector ID
                </th>
                <td>
                    {{cellidToENBConverterResult.sectorid}}
                </td>
            </tr>
            <tr *ngIf="cellidToENBConverterResult.sectoridhex != null && cellidToENBConverterResult.net == 'LTE'">
                <th>
                    Sector ID (Hex)
                </th>
                <td>
                    {{cellidToENBConverterResult.sectoridhex}}
                </td>
            </tr>
            <tr *ngIf="cellidToENBConverterResult.rncid != null && cellidToENBConverterResult.net == 'UMTS'">
                <th> RNC-ID</th>
                <td>{{cellidToENBConverterResult.rncid}}</td>
            </tr>
        </table>
    </div>
</div>