<div class="container">
    <h1> Networks Stats </h1>
    <p>Select your country and network type to see how much data saved to our database. To install our application you can grow your provider statistics easily. </p>
    <form>
        <div class="input-group">
            <label for="country">Country </label>
            <select name="country" class="form-control" id="country">
                <option value="ALL"selected>ALL </option>
                <ng-container *ngFor="let network of networks">
                    <option [value]="network">{{network}}</option>
                </ng-container>
            </select>
        </div> <br />
        <div class="input-group">
            <label for="net">Network </label>
            <select name="net" class="form-control">
                <option value="ALL">ALL</option>
                <option value="NR">5G NR</option>
                <option value="LTE">4G LTE</option>
                <option value="UMMTS">3G UMTS</option>
                <option value="GSM">2G GSM</option>
            </select>
        </div>
    </form>
</div>