<div class="container">
    <h1> Changelog</h1>
    <p> Here you can check all Android App changelogs</p>
    <h2> Android App</h2>
    <h3> 5.5.0 (2020-10-01) </h3>
    <ul>
        <li>Dual SIM stale/bad data fixes</li>
        <li>5G fixes</li>
        <li>Android 11 fixes</li>
        <li>Added 5G PCI for some Samsung devices</li>
        <li>First Run/Permission simplication/fixes for Android 10 and later</li>
        <li>Map dark mode</li>
        <li>Fixed issues with stale cell data on some Android 10/11 devices</li>
        <li>UI Improvements</li>
        <li>Updated translations and added multiple languages</li>
    </ul>
    <h3>5.4.5 (2020-08-06)</h3>
    <ul>
        <li>Fix 5G SA Cell ID being cut off</li>
        <li>Fix TAC missing on 5G SA Samsung devices</li>
        <li>Update frequency converter with latest 5G bands</li>
        <li>OP8 SA show some limited information</li>
    </ul>
    <h3>5.4.3 (2020-04-23)</h3>
    <ul>
        <li>Read 5G NSA Cell Identity/ARFCN/PCI on some Huawei devices</li>
        <li>Fixed error logging data points for providers with special characters in names (such as apostrophe or quote)</li>
        <li>Fixed NR cells showing up multiple times</li>
        <li>Fixes for Huawei phones</li>
    </ul>
    <h3>5.4.2 (2020-03-17)</h3>
    <ul>
        <li>5G Dual SIM fixes</li>
        <li>Enable Hybrid map</li>
        <li>MediaTek 2G/3G fixes for certain devices</li>
        <li>Fix uploading on Bulgarian translation</li>
    </ul>
    <h3>5.4.1 (2019-12-29)</h3>
    <ul>
        <li>5G fixes</li>
        <li>Android 10 missing data fixes</li>
        <li>Fix dates being cut off in some languages</li>
    </ul>
    <h3>5.4.0 (2019-12-18)</h3>
    <ul>
        <li>Tower map move/delete/restore support</li>
        <li>Read 5G data from some Samsung with Android 10</li>
        <li>Fix wifi auto upload on newer Android versions</li>
        <li>Workaround for Android 10 NR info reading</li>
        <li>Record SDL band data (certain phones only)</li>
        <li>Workaround for Qualcomm bug for B71 showing up as B7 for neighbour cells for US providers</li>
        <li>Fix error message displaying JSON instead of text</li>
        <li>Tablet fixes</li>
        <li>Fix curved screen edges cutoff off text in car mode</li>
        <li>Workaround for Android bug for dates in German</li>
    </ul>
    <h3>5.3.1 (2019-09-18)</h3>
    <ul>
        <li>5G NR fixes for missing signal strength indicator</li>
        <li>Disabled some options that no longer work for newer versions of Android</li>
        <li>Flash LED when recording data</li>
    </ul>
    <h3>5.3.0 (2019-06-25)</h3>
    <ul>
        <li>Adds 5G NR info for Samsung devices on Android 8.0 / Recording of EN-DC NR (BETA)</li>
        <li>Add 5G NR frequency calculator</li>
        <li>Spanish language bug fixes and updated translations for various languages</li>
    </ul>
    <h3>5.2.7 (2019-01-31)</h3>
    <ul>
        <li>Login fixes</li>
    </ul>
    <h3>5.2.6 (2019-01-03)</h3>
    <ul>
        <li>Fixed login not working on some scenarios</li>
    </ul>
    <h3>5.2.5 (2018-12-12)</h3>
    <ul>
        <li>New Android 9 API to retrieve provider names for dual SIM devices</li>
        <li>Display provider name per cell in dual SIM scenario</li>
        <li>Fixed estimated band data not working</li>
        <li>Upgraded libraries used by app</li>
        <li><strong>5G NR initial detection support</strong></li>
        <li>Improve speed detection</li>
        <li>Updated translations</li>>
    </ul>
    <h3>5.2.3 (2018-10-24)</h3>
    <ul>
        <li>Add bandwidth to car mode (if available) - Reduce network usage on map screen</li>
    </ul>
    <h3>5.2.2</h3>
    <ul>
        <li>UI bug (text/translation) fixes</li>
    </ul>
    <h3>5.2.1 (2018-10-20)</h3>
    <ul>
        <li>Added bandwidth speed recording</li>
        <li>Target latest API level</li>
        <li>Fix Essential Phone single digit MNC Android 9 bug</li>
        <li>Many bug fixes</li>
    </ul>
    <h3>5.1.7 (2018-3-15)</h3>
    <ul>
        <li>Fix B71 showing up as B7 on some faulty devices (Galaxy S9)</li>
        <li>Updated translations</li>
    </ul>
    <h3>5.1.6 (2018-2-4)</h3>
    <ul>
        <li>App Shortcuts added</li>
        <li>Allow opening in-app map when clicking a map URL</li>
        <li>Workaround for multi-PLMN networks displaying wrong PLMN (single SIM phones only)</li>
        <li>Improved login check for > 7.0 devices</li>
    </ul>
    <h3>5.1.5 (2017-11-16)</h3>
    <ul>
        <li>Add search for location to the map</li>
        <li>Remove field test screen reader due to Google demand</li>
        <li>Disable forcing screen on when mapping CDMA and 2G recording disabled</li>
        <li>Fix lines not drawing to tower for certain CDMA/LTE devices</li>
        <li>Crash fixes</li>
    </ul>
    <h3>5.1.4 (2017-9-10)</h3>
    <ul>
        <li>Add LTE Band 72/74/75 support for calculator</li>
        <li>Check login status more often</li>
        <li>Fixed CDMA signal swapped with LTE sometimes</li>
        <li>Don't ask for storage permission unless writing to CSV is enabled</li>
        <li>Code cleanup and bug fixes</li>
        <li>More translations</li>
    </ul>
    <h3>5.1.3 (2017-8-23)</h3>
    <ul>
        <li>Fixed offline map losing data</li>
        <li>Added band selection for tiles on map</li>
        <li>Added distance to connected tower on map</li>
        <li>Fix Android O crash</li>
        <li>Support more LTE bands (such as B71) in frequency calculator and app</li>
        <li>Bug fixes and more translated text</li>
        <li>Add setting for LTE timing advance adjustment</li>
    </ul>
    <h3>5.1.2 (2017-7-25)</h3>
    <ul>
        <li>Bug fixes</li>
    </ul>
    <h3>5.1.1 (2017-5-15)</h3>
    <ul>
        <li>Show provider name on car mode screen</li>
        <li>Fix some dual SIM phones reporting bad network type for 2nd SIM</li>
        <li>Fixed LTE-A not being detected on some devices</li>
        <li>Fixed bad data recorded on some devices during handover</li>
        <li>Bug fixes</li>
    </ul>
    <h3>5.1.0 (2017-4-12)</h3>
    <ul>
        <li>Tap to select country on map</li>
        <li>Support more network types</li>
        <li>Optimized for Android 7.1.1 (round icon, shortcuts)</li>
        <li>App Shortcuts (WIP)</li>
        <li>UI Improvements</li>
        <li>Better filtering for bad data during handoff</li>
        <li>Network subtypes now displayed for new API</li>
        <li>Fixed missing provider name for dual SIM phones (new API)</li>
        <li>Read DC-HSPA+ on some Sony devices</li>
        <li>Add GSM Timing Advance</li>
        <li>Implement new Android permission model</li>
        <li>Make map clustering optional</li>
        <li>Added more tower info per tower</li>
        <li>Crash fixes</li>
        <li>More translations</li>
    </ul>
</div>
