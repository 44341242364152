<div class="jumbotron">
    <div class="container">
        <h1 class="display-3">CellMapper</h1>
        <p> CellMapper is a crowd-sourced cellular tower and coverage mapping service. By contributing data you will help us to build world best coverage map service. Download app free to your phone or browse our website map. </p>
        <p class="lead">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=cellmapper.net.cellmapper">
                <img alt="Get it on Google Play" class="appstore-badge img-fluid" src="assets/google-play-badge.png">
            </a>
            <a target="_blank" href="https://appgallery.cloud.huawei.com/uowap/index.html#/detailApp/C102093103?appId=C102093103">
                <img alt="Download on AppGallery" class="appstore-badge img-fluid" src="assets/appgallery-badge.png">
            </a>
        </p>
        <a class="btn btn-primary btn-lg" routerLink="/map" role="button">Go to the Map</a>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-sm">
            <h2> How CellMapper works?</h2>
            <p>Using CellMapper is very easy, just few steps to start:</p> 
            <ol class="list-group">
                <li class="list-group-item">Download our app via Google Play or AppGallery.</li>
                <li class="list-group-item">Start recording with our map and walk/bike round of your area</li>
                <li class="list-group-item">Upload data via App to our server and wait a moment</li>
                <li class="list-group-item">Use app map or website map to browse tower locations on your area</li>
            </ol>
            <p>More instructions <a routerLink="/help">here</a>.</p> 
        </div>
        <div class="col-sm">
            <div class="col-sm">
                <h2>All our data is based on real measurement</h2>
                <p> All our data based real measurement by our contributions. Our service automatically calculate tower locations from contributed data.</p>
            </div>
        </div>
    </div>
</div>