import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ApiServiceService, CountryNetwork, CountryNetworks } from '../api-service.service';

@Component({
  selector: 'app-networks-stats-page',
  templateUrl: './networks-stats-page.component.html',
  styleUrls: ['./networks-stats-page.component.css']
})
export class NetworksStatsPageComponent implements OnInit {

  constructor(
    private titleService: Title,
    private apiService: ApiServiceService
  ) { }

  networks: CountryNetworks;

  ngOnInit(): void {
    this.titleService.setTitle('Network Stats | ' + environment.title);
    this.doGetAllNetworks();
  }

  doGetAllNetworks() {
    this.apiService.doGetAllNetworks().subscribe(networks => {
      this.networks = networks.responseData;
      console.log(this.networks);
    })
  }

}
