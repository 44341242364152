<div class="container">
    <h1>Top Users</h1>
    <p>This page lists the top 100 users who have contributed data. Page data will update every hour.</p>
    <table class="table table-striped">
        <thead>
            <th>
                Ranking
            </th>
            <th>
                User
            </th>
            <th>
                Cell Discovered
            </th>
            <th>
                Uploaded Data Points
            </th>
            <th>
                Tower Moved/Deleted
            </th>
        </thead>
    </table>
</div>
