<div class="container">
    <h1> Privacy</h1>
    <h2> Introduction </h2>
    <p>It is not necessary to make an account with CellMapper to view the data that has already been collected, or to
        map from a smartphone. However, your data could still be transmitted to the CellMapper service and you still
        have rights for this data under the laws of the European Union (the General Data Protection Regulation / GDPR
        hereafter), and all applicable local laws, that will be outlined in this document.</p>
    <h2>Personal and Sensitive Infromation</h2>
    <p>CellMapper does not collect any information deemed personal or sensitive by default. Should you wish to
        contribute to the mapping data, your coarse location ("network information") shall be provided, alongside with
        your fine location ("current location") will be sent to CellMapper servers. CellMapper does not record any
        personally identifiable information about your phone that would link the data to an individual. The section
        below outlines the data collected.</p>
    <h2>The data we collect</h2>
    <p>By using the CellMapper website your data will be collected in the following ways:</p>
    <p><strong>Cookies:</strong> Cookies (small amounts of data stored on your computer) which enable CellMapper to
        easily remember details such as your language settings and your login state are used. By using the website you
        accept this cookie policy.</p>
    <p><strong>Analytics and Advertisements:</strong> CellMapper uses analytics software for the purposes of site
        diagnostics, and to improve the user experience. This data is used to analyse how many users use the website and
        in what ways. This data is not sold to advertisers or shared with third parties beyond the analytics service.
        <br /> <br />
        For the purposes of analytics, a service known as Matomo Analytics is used. We believe that the service is
        secure, and that reasonable precautions have been taken in regard to the security of your data.
        <br /><br />
        CellMapper also makes use of the Google Advertising platform on the Website and on the Mobile Application. This
        is done to cover the operating costs of the CellMapper service, and we ask that our users do not block the
        advertisements that are served to them, and we instead ask them to consider the Cellmapper Premium service which
        is available at a low cost per month if they wish to do away with advertisements, as a way of supporting the
        CellMapper project. If you see an advertisement of an inappropriate nature, such as one that contains
        explicit/adult material, harmful to Computer/Phone material or something else that you consider inappropriate,
        please feel free to get in touch to voice your concerns, which we will take very seriously. You can also take
        action via Google to block advertisements such as this.
        <br /><br />
    <p>We collect the following information when you make an account with us</p>
    <p><strong>Username:</strong> This provides a unique identifier for the data you collect, allowing it to be
        attributed to you on the CellMapper website. Additionally by registering, you are allowed to locate, and delete
        towers or cells that have been mapped using the mobile application. Your username will be public and visible to
        other users on the website, on towers that you have interacted with (mapped whilst logged in, moved or deleted),
        or on the list of Top CellMapper users. Your username will be used additionally by you to login to the
        CellMapper website and the CellMapper application. </p>
    <p><strong>Email Address:</strong> Your email address will be used by us for verification that your account
        registration is legitimate, and in the event that your password is forgotten, to reset your password so that you
        can access your account. We also can use your email address to contact you if we need to (examples of this will
        be if we have received complaints about your actions from another user, or if you are seen to be inadvertently
        mapping incorrectly).</p>
    <p><strong>Password:</strong> This is a secret string of letters, numbers or special characters (!@£* etc), known
        only by you, which you will use to authenticate yourself onto the CellMapper website or the CellMapper
        application. This will be stored securely on the CellMapper servers using encryption and a technique known as
        “salting”. CellMapper will not be able to access your password in its “textual” format, and we consider this to
        be secure. However, as a general rule, we recommend strongly that you use a strong and secure password, which is
        different from passwords that you use with other services.</p>
    <p><strong>Profile Picture:</strong> This is loaded from an external service known as Gravatar, where a profile
        picture uploaded by you will be stored. We believe that this service is to the best of our knowledge secure. If
        you are already registered to Gravatar with the email address that you sign up to CellMapper with, then your
        profile picture will be automatically loaded into CellMapper. By using CellMapper, you will also accept the
        Terms and Conditions of Gravatar. We collect the following information when you upload data from the mobile
        application to the CellMapper servers, this information is uploaded either manually, when the upload button in
        the application is pressed, or automatically if the Live Upload feature of the application is enabled:</p>
    <p><strong>Current Location:</strong> This information includes your latitude, longitude, altitude, and bearing,
        which provides your precise location in the areas that you have mapped to the CellMapper servers. This will be
        used for the automatic calculation and processing of data points, in order to estimate the locations of mobile
        masts, and in order to generate the “Signal Trails”, which form a key part of the CellMapper service.</p>
    <p><strong>Network Information:</strong> Information about your current cellular network will be transmitted to the
        CellMapper service, this includes your Mobile Network Code and Mobile Country Code (MNC/MCC) - which identifies
        your mobile network (e.g Verizon Wireless, T-Mobile, Vodafone UK, Telia Finland, Cosmote GR, TIM Italia), this
        will be used to ensure that the data you provide is calculated for the right mobile provider. We also collect
        technical information about the mobile network, which is used for processing the data. This includes LAC/TAC,
        Cell ID (for example in the GCI format from a 4G LTE network), RSRP/RSCP/RSSI (your signal strength), your RAT
        (whether you are on 2G/3G/4G) for both serving and neighbouring cells. Additionally depending on what your phone
        supports we will collect data such as the E/U/ARFCN of your phone, PCI, PSC etc.</p>
    <p><strong>CellMapper App version:</strong> Your phone will upload the version of the CellMapper application that
        you are using to the CellMapper servers, this will be used for diagnostic purposes and will not be published.
    </p>
    <p><strong>CellMapper Account Data:</strong> The data described above relating to a CellMapper Account will be
        transmitted if you are signed in.</p>
    <p><strong>IP-Address:</strong> An identifier relating to the current internet connection you use to Upload data to
        CellMapper’s servers will be transmitted, in order to prevent Vandalism.</p>
    <p><strong>Android Version Information:</strong> Transmitted by your phone as it talks to our Servers, this
        identifies which version of Android you will be using, as well as Limited information about your device, which
        will be used for diagnostic Purposes.</p>
    <h2>Under what basis do we collect this information</h2>
    <p>Under European Union law we have to provide a clear legal basis for collecting the data that we do. We collect
        this at CellMapper based on the following two lawful bases, depending on the data being collected:</p>
    <p>Legitimate Interest: We believe that we collect data relating to your IP-Address, CellMapper Version, Android
        Version, as well as Analytics and Advertising data on the basis of “Legitimate Interest”, as this is used mainly
        for diagnostic purposes, and the prevention of malicious data / fraudulent activity involving CellMapper. The
        public benefits from this data, as it ensures the wellbeing of the CellMapper platform / project for all users
        is maintained, and allows for continuous improvement of the platform. We believe that this is a reasonable
        action to take, as the data is not particularly sensitive, and that people generally expect diagnostic
        information to be taken. This information will not have a large or common impact on individual users, and users
        can opt out of providing analytics / advertising data by using the “Do Not Track Feature”.</p>
    <p>Consent: We believe that the best legal basis for collecting data relating to your account, as well as the
        network and location data uploaded, is one of Consent, which is legally defined as an available legal basis
        under the European Union law. We believe that our implementation of Consent, on the website and on the
        application is one that can be freely given by users, on registering for the CellMapper service, and one that
        can be withdrawn.</p>
    <h2>Transfer of Information</h2>
    <p>CellMapper’s data is transferred outside of the European Union, to Canada for storing and processing. This is
        done securely, as we make use of OVH data centres for data storage and transfer. We believe that Canada has a
        strong and robust regulatory environment for data storage.</p>
    <h2>Retention of Information</h2>
    <p>CellMapper’s information is intended to serve as a long term project mapping the development of mobile networks
        as they are deployed and changed, as such the data that CellMapper holds is intended to be stored for as long as
        possible, as a record of mobile networks. Other data not pertaining to this is stored for as long as needed then
        securely deleted.</p>
    <h2>Your Rights pertaining to your Data</h2>
    <p>Under European Union Law / the GDPR you have rights relating to your data which we must make you aware of and how
        they apply to you in the context of CellMapper.</p>
    <h3>Right to be Informed</h3>
    <p>You have the right to be informed as to how we collect your data, what data we collect, how we store it and how
        we retain it. We must explain this to you in plain language, and in a transparent way. We believe we have done
        this above - if you believe this not to be the case, or if you believe that we are missing appropriate
        information, please get in touch with us and we would be more than happy to explain/revise this policy to the
        best of our ability.</p>
    <h3>Right of Access</h3>
    <p>You can request a copy of personal data stored with us, either verbally or in writing. We must typically provide
        this within one month. Please bear in mind that we have the right to request confirmation of identity for this
        data. Please also bear in mind that you have only the right to request personal data - as much of the data that
        CellMapper collects can be anonymised completely it is not according to guidelines issued by the authorities
        personal data.</p>
    <h3>Right to Rectification</h3>
    <p>If information we hold about you is wrong, then you can change it. We provide tools to do this yourself for some
        data, however if you feel that this is not adequate then please get in touch.</p>
    <h3>Right to Erasure</h3>
    <p>You have a right for personal data to be deleted. We provide tools to delete your account, however we do not
        believe collected data falls under this right, as a clear exemption is made within GDPR for archiving data for
        scientific projects or that is in the public interest - such as for statistical purposes. Erasure of data can
        serious impede this and as the data can be totally anonymised we do not consider this to fall under this right.
    </p>
    <h3>Right to Restrict Processing</h3>
    <p>In limited scenarios you can request that we restrict the processing of the personal data we hold about you.
        Please get in touch if you believe this to be the case.</p>
    <h3>Right to Data Portability</h3>
    <p>For the purpose of transferring your data to a differing service you may request a copy of your personal data in
        a commonly used format such as CSV, XML, JSON, or you can request that we transfer it to another data
        controller. Please get in touch to exercise this right.</p>
    <h3>Right to Object</h3>
    <p>According to ICO guidelines you have the right to object to us using your data for direct marketing purposes. As
        we do not do this, we do not anticipate this being a major issue. However please do get in touch if you feel you
        have another reason to exercise this right. Rights related to automated decision making GDPR specifies that
        decision making must be done on a lawful basis, we believe that this is covered under the consent given when you
        upload the data to us. We do not create specific user profiles, and processing is done just on the principle of
        the CellMapper Mapping Project. Personal data that you upload can also be viewed in your user profile.</p>
    <h3>Other rights</h3>
    <p>You have the right to file a complaint with an applicable supervisory authority over our handling of data. You
        have the right to withdraw your consent to personal data.</p>
    <h2> Change your Consent</h2>
    <p> All Cookies include all cookies, also analytics. Essential Cookies include all functional cookies exept
        tracking.
    <div class="form-inline">
        <button class="btn btn-primary toolbar" (click)="acceptConsent()"> Accept All Cookies </button>
        <button class="btn btn-secondary toolbar" (click)="denyConsent()"> Accept Essential Cookies</button>
    </div>
    <br />
    <ngb-alert class="alert-status" type="info" [dismissible]="false">
        Currently you have <ng-container *ngIf="this.consent == true">accepted all cookies</ng-container>
        <ng-container *ngIf="this.consent == false">have accepted essential cookies but not tracking cookies
        </ng-container>.
    </ngb-alert>
    <br />
    <h2>Contact</h2>
    <p>You can contact us about any aspect of CellMapper and specifically about data via email at support@cellmapper.net
    </p>
</div>