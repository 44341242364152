<div class="container">
    <h1> Premium Membership</h1>
    <p>You can subscribe to a premium CellMapper membership in order to gain extra features and have an ad-free experience on both the website and the mobile app.
        <br />Some features include:</p>
        <ul>
            <li>Ad-free main website</li>
            <li>Ad-free mobile app (5.1.4 and higher)</li>
            <li>Dedicated infrastructure that speeds up processing of your tower data significantly (For now this doesn't affect tile data prosessing speed)</li>
        </ul>
        <br />
        <p> Premium Subscription price is 3 CAD per month. You can manage subscription in <a target="_blank" rel="noopener" href="https://www.paypal.com/myaccount/autopay/">Paypal Settings</a>.</p>
    <div class="loader" *ngIf="loaded == false">
            <div class="app-loading">
                <div class="logo"></div>
                <svg class="spinner" viewBox="25 25 50 50">
                  <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
              </div>
        </div>
    <ng-container *ngIf="loggedin == true && loaded == true && premiumStatus != null">
        <ng-container *ngIf="premiumStatus == true">
            <div class="alert alert-info">
                You already have a premium membership, thank you for your support!
            </div>
        </ng-container>
        <ng-container *ngIf="premiumStatus == false">
            <p>In order to subscribe, click on the button below once you are logged in. Once you subscribe, you will gain access to these features within a few minutes of payment verification.</p>
            <form ngNoForm action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_s-xclick">
                <input type="hidden" name="hosted_button_id" value="GARW8C5LJ7JZE">
                <input type="hidden" name="custom" value="{{UserID}}"/>
                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
                </form>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="loggedin == false && loaded == true">
        <div class="alert alert-info">
            You must login in order to subscribe
        </div>
    </ng-container>
</div>