<div class="container">
    <h1> Frequency Calculator</h1>
    <p>This page allows you to calculate the exact frequency from the channel numbers for 5G NR, 4G LTE, UMTS, GSM, CDMA and iDEN networks. <br />
        In order to obtain the ARFCN/UARFCN/EARFCN, you will need to enter "Field Test Mode" in your phone. This varies greatly from phone to phone. In general, all involve dialing certain special codes in the dialer in order to bring up the hidden options.
    </p>
    <p>Remember to use the "downlink" or "RX" (U)ARFCN</p>
    <form method="POST" #frequencyCalculatorForm="ngForm" (submit)="submitForm()">
        <div class="form-group">
            <label for="NET">Select Network Type</label>
            <select name="net" id="net" [(ngModel)]="formData.net" class="form-control" (ngModelChange)="changeNET()">
                <option value="LTE">4G LTE</option>
                <option value="NR">5G NR</option>
                <option value="UMTS">3G UMTS</option>
                <option value="GSM">2G GSM</option>
                <option value="CDMA">2G CDMA</option>
                <option value="iDEN">2G iDEN</option>
              </select>
        </div>
        <div class="form-group">
            <label for="ARFCN">E/U/ARFCN number (Downlink only)</label>
            <input type="number" class="form-control" [class.in-valid]="formData.arfcn == null" [(ngModel)]="formData.arfcn" id="arfcn" name="arfcn" required/>
            <div class="invalid-feedback">Please provide E/U/ARFCN number</div>
        </div>
        <div class="form-group" *ngIf="showBandClass == true">
            <label for="BandClass">Band Class</label>
            <input type="number" class="form-control" [class.in-valid]="formData.bandClass == null" [(ngModel)]="formData.bandClass" id="bandClass" name="bandClass"/>
            <div class="invalid-feedback">Please provide Band Class number</div>
        </div>
        <div class="form-group">
            <label for="MCC">Select region</label>
            <select class="form-control" name="MCC" id="MCC" [(ngModel)]="formData.mcc">
                <option value="0">World</option>
                <option value="302">North America</option>
                <option value="222">EMEA</option>
                <option value="515">APAC</option>
                <option value="440">Japan</option>
                <option value="460">China</option>
            </select>
        </div>
        <button type="submit" name="submit" [disabled]="submitDisabled == true || !frequencyCalculatorForm.form.valid || formData.arfcn == null || (showBandClass == true && formData.bandClass == null)" class="btn btn-primary">Calculate</button>
    </form>
    <br />
    <div class="table-responsive" *ngIf="frequencyData != null">
        <table class="table">
            <tbody>
                <tr *ngIf="frequencyData.netType != null">
                    <th>
                        Network Type
                    </th>
                    <td>
                        {{frequencyData.netType}}
                    </td>
                </tr>
                <tr *ngIf="frequencyData.Frequency != null">
                    <th>
                        <ng-container *ngIf="frequencyData.netType == 'NR'">
                            NR-ARFCN
                        </ng-container>
                        <ng-container *ngIf="frequencyData.netType == 'LTE'">
                            EARFCN
                        </ng-container>
                        <ng-container *ngIf="frequencyData.netType == 'UMTS'">
                            UARFCN
                        </ng-container>
                        <ng-container *ngIf="frequencyData.netType == 'GSM' || frequencyData.netType == 'CDMA'">
                            E/U/ARFCN
                        </ng-container>
                    </th>
                    <td>
                        {{frequencyData.Frequency}}
                    </td>
                </tr>
                <tr *ngIf="frequencyData.bandName != null">
                    <th>
                        Band Name
                    </th>
                    <td>
                        {{frequencyData.bandName}}
                    </td>
                </tr>
                <tr *ngIf="frequencyData.txFrequency != null">
                    <th>
                        Uplink Frequency (phone to base station)
                    </th>
                    <td>
                        {{frequencyData.rxFrequency}} MHz
                    </td>
                </tr>
                <tr *ngIf="frequencyData.rxFrequency != null">
                    <th>
                        Downlink Frequency (base station to phone)
                    </th>
                    <td>
                        {{frequencyData.txFrequency}} MHz
                    </td>
                </tr>
                <tr *ngIf="frequencyData.bandNumber != null">
                    <th>
                        Band number
                    </th>
                    <td>
                        {{frequencyData.bandNumber}}
                    </td>
                </tr>
                <tr *ngIf="frequencyData.possibleBandwidth != null">
                    <th>
                        Possible Bandwidts
                    </th>
                    <td>
                        {{frequencyData.possibleBandwidth}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>