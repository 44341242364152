import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_BASE_URL, RECAPTCHA_SETTINGS } from "ng-recaptcha";
import { CookieModule, CookieService } from 'ngx-cookie';
import { Angulartics2Module } from 'angulartics2';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomePageComponent } from './home-page/home-page.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PrivacyPageComponent } from './privacy-page/privacy-page.component';
import { TermsPageComponent } from './terms-page/terms-page.component';
import { MapPageComponent } from './map-page/map-page.component';
import { HttpClientModule } from '@angular/common/http';
import { ChangelogPageComponent } from './changelog-page/changelog-page.component';
import { FrequencyCalculatorPageComponent } from './frequency-calculator-page/frequency-calculator-page.component';
import { CellIdToEnbConverterPageComponent } from './cell-id-to-enb-converter-page/cell-id-to-enb-converter-page.component';
import { FormsModule } from '@angular/forms';
import { TopUsersStatsPageComponent } from './top-users-stats-page/top-users-stats-page.component';
import { NetworksStatsPageComponent } from './networks-stats-page/networks-stats-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { ToastContainerComponent } from './toast-container/toast-container.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PremiumPageComponent } from './premium-page/premium-page.component';


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    PrivacyPageComponent,
    TermsPageComponent,
    MapPageComponent,
    ChangelogPageComponent,
    FrequencyCalculatorPageComponent,
    CellIdToEnbConverterPageComponent,
    TopUsersStatsPageComponent,
    NetworksStatsPageComponent,
    NotFoundPageComponent,
    RegisterPageComponent,
    ToastContainerComponent,
    PremiumPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    FontAwesomeModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    CookieModule.forRoot(),
    Angulartics2Module.forRoot({
      developerMode: typeof document == 'undefined' || !environment.production
    }),
  ],
  providers: [
    CookieService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.RECAPTCHA_SITE_KEY } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: "https://recaptcha.net/recaptcha/api.js", // use recaptcha.net script source for some of our users
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
