import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ToastServiceService } from '../toast-service.service';

export interface RegisterForm {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  captchaCode: string;
}

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.css']
})
export class RegisterPageComponent implements OnInit {

  formData: RegisterForm = { username: '', email: '', password: '', confirmPassword: '', captchaCode: '' };
  submitDisabled: boolean;

  constructor(
    private titleService: Title,
    public toastService: ToastServiceService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Register | ' + environment.title);
  }

  // Check Email validation https://ui.dev/validate-email-address-javascript/
  emailIsValid(email) {
      return /\S+@\S+\.\S+/.test(email)
  }

  doRegister() {
    //this.submitDisabled = true;
    this.toastService.show('This is not yet ready to use', { classname: 'bg-danger text-light', delay: 10000 });
  }

}
