import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CellIdToEnbConverterPageComponent } from './cell-id-to-enb-converter-page/cell-id-to-enb-converter-page.component';
import { ChangelogPageComponent } from './changelog-page/changelog-page.component';
import { FrequencyCalculatorPageComponent } from './frequency-calculator-page/frequency-calculator-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { MapPageComponent } from './map-page/map-page.component';
import { NetworksStatsPageComponent } from './networks-stats-page/networks-stats-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { PremiumPageComponent } from './premium-page/premium-page.component';
import { PrivacyPageComponent } from './privacy-page/privacy-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { TermsPageComponent } from './terms-page/terms-page.component';
import { TopUsersStatsPageComponent } from './top-users-stats-page/top-users-stats-page.component';

const routes: Routes = [
  { path: 'premium', component: PremiumPageComponent },
  { path: 'subscribe', redirectTo: '/premium'},
  { path: 'topUsers', component: TopUsersStatsPageComponent},
  { path: 'networks', component: NetworksStatsPageComponent},
  { path: 'enbid', component: CellIdToEnbConverterPageComponent},
  { path: 'arfcn', component: FrequencyCalculatorPageComponent},
  { path: 'map', component: MapPageComponent},
  { path: 'privacy', component: PrivacyPageComponent},
  { path: 'terms', component: TermsPageComponent},
  { path: 'changelog', component: ChangelogPageComponent},
  { path: 'register', component: RegisterPageComponent},
  { path: '', component: HomePageComponent},
  { path: '404', component: NotFoundPageComponent},
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }