import { Component, OnInit } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import OSM from 'ol/source/OSM';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.css']
})
export class MapPageComponent implements OnInit {
  map: any;

  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Map | ' + environment.title);
            
    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM({crossOrigin: 'anonymous'})
        })
      ],
      view: new View({
        center: olProj.fromLonLat([0, 0]),
        enableRotation: false,
        zoom: 5
      })
    });
  }

}
