import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ApiServiceService } from '../api-service.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-premium-page',
  templateUrl: './premium-page.component.html',
  styleUrls: ['./premium-page.component.css']
})
export class PremiumPageComponent implements OnInit { 

  loaded: boolean;
  loggedin: boolean = false;
  premiumStatus: boolean;
  UserID: number;

  constructor(
    private titleService: Title,
    public appComponent: AppComponent,
    private apiService: ApiServiceService,
  ) { }


  ngOnInit(): void {
    this.titleService.setTitle('Premium Membership | ' + environment.title);
    this.checkLogin();
  }

  checkLogin() {
    this.loaded = false;
    this.apiService.doCheckLogin().subscribe(login => {
      if(login.responseData.loginCheckResponseCode == "LOGGEDIN") {
        this.loggedin = true;
        this.getUserInfo();
      }
      else {
        this.loggedin = false;
      }
      this.loaded = true;
    },
    error => {
      this.loggedin = false;
      this.loaded = true;
    }
    )
  }

  getUserInfo() {
    this.loaded = false;
    this.apiService.doGetUserInfo().subscribe(userInfo => {
      if(userInfo.statusCode == "OKAY") {
        this.premiumStatus = userInfo.responseData.premium;
        this.UserID = userInfo.responseData.id;
      }
      this.loaded = true;
    })
  }


}
