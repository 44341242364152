import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.css']
})
export class PrivacyPageComponent implements OnInit {

  constructor(
    private titleService: Title,
    private appComponent: AppComponent
  ) {
    this.checkConsent()
  }

  denyConsentDisable: boolean;
  allowConsentDisable: boolean;
  consent: boolean;

  ngOnInit(): void {
    this.titleService.setTitle('Privacy | ' + environment.title);
  }

  checkConsent() {
    this.consent = this.appComponent.consent;
  }

  acceptConsent() {
    this.appComponent.acceptConsent();
    this.allowConsentDisable = true;
    this.consent = true;
  }
  denyConsent() {
    this.appComponent.denyConsent();
    this.denyConsentDisable = true;
    this.consent = false;
  }

}
