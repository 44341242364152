import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ApiServiceService, FrequencyData } from '../api-service.service';
import { ToastServiceService } from '../toast-service.service';

export interface FrequencyCalculatorForm {
  net: 'LTE' | 'NR' | 'UMTS' | 'GSM' | 'CDMA' | 'iDEN';
  arfcn: number;
  bandClass: number;
  mcc: number;
}

@Component({
  selector: 'app-frequency-calculator-page',
  templateUrl: './frequency-calculator-page.component.html',
  styleUrls: ['./frequency-calculator-page.component.css']
})
export class FrequencyCalculatorPageComponent implements OnInit {

  submitDisabled: boolean = false;
  formData: FrequencyCalculatorForm = {net: 'LTE', arfcn: null, bandClass: null, mcc: 0}
  showBandClass: boolean = false;
  frontendError: boolean = false;

  statusCode: string;
  frequencyData: FrequencyData;

  constructor(
    private titleService: Title,
    private apiService: ApiServiceService,
    public toastService: ToastServiceService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Frequency Calculator | ' + environment.title);
  }

  changeNET() {
    if(this.formData.net == "CDMA") {
      this.showBandClass = true;
    }
    else {
      this.showBandClass = false;
    }
  }

  submitForm() {
    this.submitDisabled = true;

    this.submitDisabled = false;

    this.apiService.doGetFrequency(this.formData.arfcn, this.formData.net, this.formData.mcc, this.formData.bandClass).subscribe(frequency => {
      this.statusCode = frequency.statusCode;
      this.frequencyData = frequency.responseData;
      if(frequency.statusCode == "ERROR") {
        this.toastService.show('Cannot calculate with the data provided.', { classname: 'bg-danger text-light', delay: 10000 });
      }
    })
  }
}
