<nav ngbNav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <a class="navbar-brand" routerLink="" (click)="navbarCollapsed = true">
    <img src="assets/CellMapperLogo-small.png" alt="CellMapper Logo" class="d-inline-block">
  </a>
  <button (click)="navbarCollapsed = !navbarCollapsed" [attr.aria-expanded]="!navbarCollapsed" class="navbar-toggler"
    type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div [ngbCollapse]="navbarCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li ngbNavItem>
        <a class="nav-link" routerLink="/map" (click)="navbarCollapsed = true"> <i class="nav-logos fas fa-map"></i>
          <fa-icon [icon]="faMap"></fa-icon> Map
        </a>
      </li>
      <li ngbDropdown class="nav-item dropdown">
        <a ngbDropdownToggle class="nav-link" id="ToolsDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <fa-icon [icon]="faWrench"></fa-icon> Tools
        </a>
        <div ngbDropdownMenu aria-labelledby="ToolsDropdown">
          <a ngbDropdownItem (click)="navbarCollapsed = true" routerLink="/arfcn"> Frequency Calculator</a>
          <a ngbDropdownItem (click)="navbarCollapsed = true" routerLink="/enbid"> Cell ID to eNB ID Converter</a>
          <a ngbDropdownItem (click)="navbarCollapsed = true" target="_blank"
            href="https://tools.pedroc.co.uk/4g-speed/"> 4G Speed Calculator</a>
          <a ngbDropdownItem (click)="navbarCollapsed = true" target="_blank"
            href="https://tools.pedroc.co.uk/5g-speed/"> 5G Speed Calculator</a>
        </div>
      </li>
      <li ngbDropdown class="nav-item dropdown">
        <a ngbDropdownToggle class="nav-link" id="StatisticsDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <fa-icon [icon]="faChartLine"></fa-icon> Statistics
        </a>
        <div ngbDropdownMenu aria-labelledby="StatisticsDropdown">
          <a ngbDropdownItem (click)="navbarCollapsed = true" routerLink="/topUsers"> Top Users</a>
          <a ngbDropdownItem (click)="navbarCollapsed = true" routerLink="/networks"> Network Stats</a>
        </div>
      </li>
      <li ngbDropdown class="nav-item dropdown">
        <a ngbDropdownToggle class="nav-link" id="HelpDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <fa-icon [icon]="faQuestion"></fa-icon> Help
        </a>
        <div ngbDropdownMenu aria-labelledby="HelpDropdown">
          <a ngbDropdownItem (click)="navbarCollapsed = true"> Start use App</a>
          <a ngbDropdownItem (click)="navbarCollapsed = true" routerLink="/changelog"> Changelog </a>
          <a ngbDropdownItem (click)="navbarCollapsed = true" target="_blank"
            href="https://cellmapper.freshdesk.com/support/solutions"> FAQ </a>
          <a ngbDropdownItem (click)="navbarCollapsed = true" target="_blank"
            href="https://cellmapper.freshdesk.com/support/tickets/new">
            <fa-icon [icon]="faTicketAlt"></fa-icon> Open a Support Ticket
          </a>
        </div>
      </li>
      <li ngbNavItem>
        <a class="nav-link" routerLink="/premium" (click)="navbarCollapsed = true">
          <fa-icon [icon]="faLevelUpAlt"></fa-icon> Premium
        </a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
      <ng-container *ngIf="loggedin == false">
        <li ngbNavItem>
          <a class="nav-link" style="cursor: pointer;" routerLink="/register" (click)="navbarCollapsed = true"> <i
              class="nav-logos fas fa-map"></i>
            <fa-icon [icon]="faUserPlus"></fa-icon> Register
          </a>
        </li>
        <li ngbNavItem>
          <a class="nav-link" style="cursor: pointer;" (click)="open(login)"> <i class="nav-logos fas fa-map"></i>
            <fa-icon [icon]="faSignInAlt"></fa-icon> Login
          </a>
        </li>
      </ng-container>
      <ng-container *ngIf="loggedin == true">
        <li ngbDropdown class="nav-item">
          <a ngbDropdownToggle class="nav-link" id="AccountDrowdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <fa-icon [icon]="faUserCircle"></fa-icon>
            <ng-container *ngIf="username != ''"> {{username}}</ng-container>
            <ng-container *ngIf="username == ''">Account</ng-container>
          </a>
          <div class="dropdown-menu-right" ngbDropdownMenu aria-labelledby="AccountDrowdown">
            <a ngbDropdownItem (click)="navbarCollapsed = true" style="cursor: pointer;" (click)="openProfile(profile)">
              <fa-icon [icon]="faUser"></fa-icon> Profile
            </a>
            <a ngbDropdownItem (click)="navbarCollapsed = true" style="cursor: pointer;" (click)="open(password)">
              <fa-icon [icon]="faKey"></fa-icon> Change Password
            </a>
            <a ngbDropdownItem (click)="navbarCollapsed = true" style="cursor: pointer;" (click)="doLogout()">
              <fa-icon [icon]="faSignOutAlt"></fa-icon> Log Out
            </a>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>
<app-toast-container aria-live="polite" aria-atomic="true"></app-toast-container>
<div class="body-container">
  <router-outlet></router-outlet>
</div>
<div style="margin-bottom:60px;"></div>
<footer>
  <div class="container">
    <p class="copyright text-muted">&copy; CellMapper | <a routerLink="/terms"> Terms </a> | <a routerLink="/privacy">
        Privacy </a> </p>
  </div>
</footer>
<ngb-alert class="alert-status consent-alert" type="dark" [dismissible]="false" (close)="closeAlert()"
  *ngIf="this.hideConsent == false">
  This website uses cookies to improve user experience. <a routerLink="/privacy"> Learn More </a>. <button
    class="btn btn-primary" (click)="acceptConsent()">Accept Cookies</button>
</ngb-alert>
<ng-template #login let-modal>
  <form (ngSubmit)="doLogin()" #loginModalForm="ngForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Login</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <p>Please enter your login credentials below. To register, click <a routerLink="register">here</a></p>
        <div class="input-group">
          <input type="text" id="loginUsername" class="form-control" placeholder="Username" name="username"
            [(ngModel)]="loginForm.username" autocomplete="username" required>
          <div class="invalid-feedback">Please give your account username</div>
        </div> <br />
        <div class="input-group">
          <input type="password" id="loginPassword" class="form-control" placeholder="Password" name="password"
            [(ngModel)]="loginForm.password" autocomplete="current-password" required>
          <div class="invalid-feedback">Please give your account password</div>
        </div> <br />
        <div class="input-group">
          <re-captcha #reCaptcha id="captcha" [(ngModel)]="loginForm.captchaCode" required name="captcha">
          </re-captcha>
        </div>
      </div>
      <div class="alert alert-success" *ngIf="loginFormResultStatus == true">
        Login Success
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="open(forgot)">Forgot password</button>
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">Cancel</button>
      <button type="submit" class="btn btn-success"
        [disabled]="submitDisabled == true || !loginModalForm.form.valid || loginForm.username.length == 0 || loginForm.password.length == 0 || loginForm.captchaCode == null || loginForm.captchaCode.length == 0">Login</button>
    </div>
  </form>
</ng-template>
<ng-template #profile let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <h3> Statistics</h3>
          <table class="table table-striped">
            <tr *ngIf="userInfo.totalPoints != null">
              <th> Uploaded Points </th>
              <td>{{userInfo.totalPoints}}</td>
            </tr>
            <tr *ngIf="userInfo.totalCells != null">
              <th>Discovered Cells</th>
              <td>{{userInfo.totalCells}}</td>
            </tr>
            <tr *ngIf="userInfo.totalLocatedTowers != null">
              <th>Moved Towers</th>
              <td>{{userInfo.totalLocatedTowers}}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6">
          <h3> Details </h3>
          <table class="table table-striped">
            <tr>
              <th>Email</th>
              <td><a href="#"> Change Email </a></td>
            </tr>
            <tr>
              <th>Registered</th>
              <td>{{userInfo.registered | date}}</td>
            </tr>
            <tr>
              <th>Password</th>
              <td><a href="#" (click)="open(password)">Change Password</a></td>
            </tr>
            <tr>
              <th>Password Updated</th>
              <td>{{userInfo.passwordUpdated | date}}</td>
            </tr>
            <tr>
              <th>Premium</th>
              <td>
                <ng-container *ngIf="userInfo.premium == true">Yes</ng-container>
                <ng-container *ngIf="userInfo.premium == false">No</ng-container>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <a href="#" (click)="open(deleteSessions)">Delete Sessions</a>
              </td>
            </tr>
            <tr>
              <td></td>
              <td><a href="#" (click)="open(deleteAccount)">Delete Account</a></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
<ng-template #forgot let-modal>
  <form #ForgotModalForm="ngForm" (ngSubmit)="forgotPassword()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Forgot Password?</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Please give to us your account email or username to start reset your account password. We will send to your
        email link where you can reset password. </p>
      <div class="input-group">
        <input type="username" class="form-control" id="username" name="username"
          [(ngModel)]="resetPasswordForm.username" placeholder="Username or Email" autocomplete="username">
      </div><br />
      <div class="input-group">
        <re-captcha #reCaptcha id="captcha" [(ngModel)]="resetPasswordForm.captchaCode" name="captcha">
        </re-captcha>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">Cancel</button>
      <button type="button" class="btn btn-success"
        [disabled]="!ForgotModalForm.form.valid || resetPasswordForm.username.length == 0 || resetPasswordForm.captchaCode.length == 0">Reset
        Password</button>
    </div>
  </form>
</ng-template>
<ng-template #password let-modal>
  <form #PasswordModalForm="ngForm" (ngSubmit)="changePassword()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Change your account password. We recommended having a secure password which is not used on any other services
      </p>
      <div class="input-group">
        <input type="password" class="form-control" id="cpassword" name="cpassword"
          [(ngModel)]="changePasswordForm.cpassword" placeholder="Current Password" autocomplete="current-password"
          required>
      </div><br />
      <div class="input-group">
        <input type="password" class="form-control" id="npassword" name="npassword"
          [(ngModel)]="changePasswordForm.npassword" placeholder="New Password"
          [class.is.invalid]="changePasswordForm.npassword.length != 0 && changePasswordForm.npassword.length < 4 "
          [class.is-valid]="changePasswordForm.npassword.length > 5" autocomplete="new-password" required>
      </div><br />
      <div class="input-group">
        <input type="password" class="form-control" id="cnpassword" name="cnpassword"
          [ngModel]="changePasswordForm.cnpassword" placeholder="Confirm New Password" autocomplete="new-password"
          required>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="open(forgot)">Forgot password</button>
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">Cancel</button>
      <button type="submit" class="btn btn-success" [disabled]="!PasswordModalForm.form.valid">Change Password</button>
    </div>
  </form>
</ng-template>
<ng-template #deleteSessions let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Sessions</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Do you really like to delete login sessions? After sessions deleted, you have to login again to your devices.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="deletesessions()">Delete Sessions</button>
  </div>
</ng-template>
<ng-template #deleteAccount let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Account</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Do you really like delete account? After account deleted there is no way to restore.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="deleteaccount()">Delete Account</button>
  </div>
</ng-template>
<ng-template #reCaptcha let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Recaptcha</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="input-group">
        <re-captcha #reCaptcha id="captcha" [(ngModel)]="loginForm.captchaCode" required name="captcha">
        </re-captcha>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="deleteaccount()">Delete Account</button>
  </div>
</ng-template>