import { Component, ViewChild } from '@angular/core';
import { faMap, faThumbsDown, faUser, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faChartLine, faDownload, faKey, faLevelDownAlt, faLevelUpAlt, faQuestion, faSignInAlt, faSignOutAlt, faTicketAlt, faToolbox, faUserPlus, faWrench } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { ApiServiceService, UserInfo } from './api-service.service';
import { ToastServiceService } from './toast-service.service';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2Piwik } from 'angulartics2/piwik';

export interface LoginForm {
  username: string
  password: string
  captchaCode: string
}

// Change Password Form: Current, New and Confirm New password
export interface ChangePasswordForm {
  cpassword: string;
  npassword: string;
  cnpassword: string;
}

// Reset Password Form
export interface ResetPasswordForm {
  username: string,
  captchaCode: string
}

declare var _paq: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CellMapper.net';

  loggedin = false;

  navbarCollapsed = true;

  //Font-Awesome Icons
  faMap = faMap;
  faWrench = faWrench;
  faDownload = faDownload;
  faChartLine = faChartLine;
  faQuestion = faQuestion;
  faUserPlus = faUserPlus;
  faKey = faKey;
  faSignInAlt = faSignInAlt;
  faSignOutAlt = faSignOutAlt;
  faTicketAlt = faTicketAlt;
  faUserCircle = faUserCircle;
  faUser = faUser;
  faLevelUpAlt = faLevelUpAlt

  closeResult = '';

  submitDisabled: boolean;

  // Login Form
  loginForm: LoginForm = { username: '', password: '', captchaCode: '' };
  loginFormResultStatus: boolean = false;
  loginStatus: string;

  // User Details
  username: string;
  userInfo: UserInfo;

  // Change Password
  changePasswordForm: ChangePasswordForm = {cpassword: '', npassword: '', cnpassword: ''};

  // Reset Password
  resetPasswordForm: ResetPasswordForm = {username: '', captchaCode: ''}

  // Consent
  hideConsent: boolean = false;
  consent: boolean = false;
  statusHide: boolean = false;

  reCaptcha: RecaptchaComponent;

  siteKey: string = environment.RECAPTCHA_SITE_KEY;

  constructor(
    private modalService: NgbModal,
    private apiService: ApiServiceService,
    public toastService: ToastServiceService,
    private angulartics2Piwik: Angulartics2Piwik
    ) {
    angulartics2Piwik.startTracking();
    this.checkLogin();
    this.checkConsent();
  }

  open(content) {
    this.navbarCollapsed = true
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openProfile(content) {
    this.navbarCollapsed = true
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openRecaptcha(content) {
    this.navbarCollapsed = true
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  };

  checkLogin() {
    this.apiService.doCheckLogin().subscribe(login => {
      if(login.responseData.loginCheckResponseCode == "LOGGEDIN") {
        this.loggedin = true;
        this.getUserInfo()
      }
      else {
        this.loggedin = false;
      }
    }
    )
  }

  doLogin() {
    this.submitDisabled = true;

    if(this.loginForm.username != '' && this.loginForm.password != '' && this.loginForm.captchaCode != '') {
      this.apiService.doLogin(this.loginForm.username, this.loginForm.password, this.loginForm.captchaCode).subscribe(login => {
        if(login.statusCode == "OKAY") {
          this.toastService.show("Login Succesfull", { classname: 'bg-success text-light', delay: 10000 });
          this.checkLogin();
        }
        else if(login.statusCode == "NEED_RECAPTCHA") {
          this.toastService.show('Please complete the reCAPTCHA', { classname: 'bg-info text-light', delay: 10000 });
        }
        else {
          this.toastService.show('Login failed', { classname: 'bg-danger text-light', delay: 10000 });
        }
        
      })
    }
    else {
      this.loginFormResultStatus = false;
    }

    this.submitDisabled = false;
    this.loginForm.captchaCode = "";
  }

  doLogout() {
    this.apiService.doLogOut().subscribe(logout => {
      if(logout.statusCode == "OKAY") {
        this.toastService.show("Logout Succesfull", { classname: 'bg-success text-light', delay: 10000 });
        this.checkLogin();
      }
      else{
        this.toastService.show('Logout failed', { classname: 'bg-danger text-light', delay: 10000 });
      }
    })
  }

  getUserInfo() {
    this.apiService.doGetUserInfo().subscribe(userInfo => {
      if(userInfo.statusCode == "OKAY") {
        this.username = userInfo.responseData.userName;
        this.userInfo = userInfo.responseData;
      }
      else if(userInfo.statusCode == "NEED_RECAPTCHA") {
        this.openRecaptcha('#reCaptcha');
      }
      else {
        this.toastService.show('Error loading UserInfo', { classname: 'bg-danger text-light', delay: 10000 });
      }
    })
  }

  changePassword() {
    this.toastService.show('This is not yet ready to use', { classname: 'bg-danger text-light', delay: 10000 });
  }

  forgotPassword() {
    this.toastService.show('This is not yet ready to use', { classname: 'bg-danger text-light', delay: 10000 });
  }

  deleteaccount() {
    this.toastService.show('This is not yet ready to use', { classname: 'bg-danger text-light', delay: 10000 });
  }

  deletesessions() {
    this.toastService.show('This is not yet ready to use', { classname: 'bg-danger text-light', delay: 10000 });
  }

  closeAlert() {
    this.statusHide = true;
  }

  // Cookie Consent
  checkConsent() {
    // All Cookies Accepted
    if(this.apiService.getCookie('mtm_cookie_consent') || this.apiService.getCookie('mtm_consent')) {
      this.hideConsent = true;
      this.consent = true;
    }
    // Essential Cookies Accepted
    if (this.apiService.getCookie('mtm_consent_removed') || this.apiService.getCookie('deny_consent')) {
      this.hideConsent = true;
      this.consent = false;
    }
  }

  public acceptConsent() {
    if (typeof _paq !== 'undefined') {
      _paq.push(['rememberCookieConsentGiven']);
      this.apiService.eraseCookie('deny_consent');
      this.apiService.eraseCookie('mtm_consent_removed');
      this.hideConsent = true;
    }
  }
  public denyConsent() {
    if (typeof _paq !== 'undefined') {
      _paq.push(['forgetCookieConsentGiven']);
      this.hideConsent = true;
      this.apiService.setCookie('deny_consent', '1', 360);
    }
  }
}
