<div class="container">
    <div class="row">
        <div class="col-md">
            <h1> Register</h1>
            <p>Please register in order to have your contributions made <strong>public</strong> on the site. Your username will be displayed under the details section of the towers which you have contributed data to. </p>
        </div>
        <div class="col-md">
            <p>Please fill out the form below.</p>
            <form method="POST" #registerForm="ngForm" (ngSubmit)="doRegister()">
                <div class="form-group">
                    <input type="text" id="registerUsername" class="form-control" name="username" [(ngModel)]="formData.username" placeholder="Username" autocomplete="username" required />
                    <div class="invalid-feedback">Please give your new account username</div>
                </div>
                <div class="form-group">
                    <input type="email" id="registerEmail" class="form-control" name="email" [class.is-valid]="emailIsValid(formData.email)" [(ngModel)]="formData.email" placeholder="Email" autocomplete="email" required/>
                    <div class="invalid-feedback">Please give your account email address, example username@example.org</div>
                </div>
                <div class="form-group">
                    <input type="password" id="registerNpassword" class="form-control" name="password" [class.is-valid]="formData.confirmPassword.length >= 7" [(ngModel)]="formData.password" placeholder="Password" autocomplete="new-password" required/>
                    <div class="invalid-feedback">Please give your account password</div>
                </div>
                <div class="form-group">
                    <input type="password" id="registerCnpassword" class="form-control" name="cnpassword" [class.is-valid]="formData.confirmPassword.length != 0 && formData.password == formData.confirmPassword" [(ngModel)]="formData.confirmPassword" [class.is-invalid]="(formData.confirmPassword.length != 0) && (formData.password != formData.confirmPassword)" placeholder="Confirm Password" autocomplete="new-password" required/>
                    <div class="invalid-feedback">Please check this password confirmation is same</div>
               </div>
               <div class="input-group">
                <re-captcha #reCaptcha id="captcha" [(ngModel)]="formData.captchaCode" required name="captcha">
                </re-captcha>
              </div> <br />
               <p>By clicking Register, you agree to our <a routerLink="terms">Terms</a> and <a routerLink="privacy">Privacy policy</a>.</p>
               <button type="submit" name="submit" class="btn btn-primary mb-3" [disabled]="!registerForm.form.valid">Register</button>
            </form>
        </div>
    </div>
</div>